@import url('https://fonts.googleapis.com/css?family=Handlee|Palanquin');


$primary: #34431C; /* MAIN COLOR */
$secondary: #D77B37; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;s
body {
	font-family: 'Palanquin', sans-serif;
}
p,a {
	font-family: 'Dosis', sans-serif;
}
.flash {
	display:none;
}
h1,h2,h3,h4,h5,h6 {
	font-family: 'Handlee', cursive;

}
.top-pad {
	margin-top: 120px;
	font-size: 1.2em;
	color:$blk;
	h1,h2,h3,h4,h5,h6 {
		font-size: 2em;
		color: $blk;
	}
	@media(max-width:767px){
		margin-top: 85px;
	}
}
nav {
	z-index: 1000;
}


h2 {
	font-size: 2.5em;
	color: $wht;
	@media(max-width: 767px){
		font-size: 1.5em;

	}
}
.cta {
	padding: 15px 20px;
	background: $primary;
	color: $wht;
	font-size: 1.2em;
	margin: 5px;
	&:hover{
		border-radius: 20px;
		color: $wht;
		background: darken($primary, 10%);
		transition: all 0.7s ease 0s;

	}
}
.navbarFixed {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height:100px;
		display: flex;
		outline: none;
		font-size: 1.2em;
		align-items: center;
		color:$blk;


		@media (max-width: 767px) {
		    height: 20px;
		    display: inline-block;
				padding: 2px;
		}

	    &:hover,  {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
			&:focus {
				outline: none;
				color: $blk;
				background-color: transparent;
			}
	}
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 767px) {
		  margin: 15px 15px 8px 0px;
	}
}
.logo {
	@media(max-width: 767px){
		max-height: 70px;
	}
}
.nav .nav-divider {
    margin: 2px 0;

}
/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
section {
	background-size: cover;
	background-repeat: no-repeat;;
	background-position:  center;
}

.hero-banner {
	background-image:url('../img/hero-banner.jpg');
	padding: 350px 0;
	@media (max-width: 1024px){
		background-image:url('../img/hero-banner-mb.jpg');
		padding: 150px 0;
	}
	@media (max-width: 767px){
		background-image:url('../img/hero-banner-mb.jpg');
		padding: 100px 0;
	}
}

.cta-banner {
	background-color:$primary;
	padding: 150px 0;
	@media(max-width: 767px){
		padding: 80px 0;
	}
	.cta {
		background:darken($primary, 5%);
	}
}
.form {

	background: $secondary; /* Old browsers */



	padding: 20px 0;
}
.box-info {
	background: url('../img/pattern.png')repeat;
	padding: 100px 0 120px 0;
	@media(max-width: 767px){
		padding: 20px 0 60px 0;
	}
	@media(min-width: 768px) and (max-width: 1024px){
		padding: 40px 0 60px 0;
	}
	h2 {
		color: $blk;
		padding: 45px 0;
	}
	h3 {
		font-size: 3em;
		font-weight: bold;
		color: darken($primary, 20%);
		padding-bottom: 35px;
		@media(max-width:767px){
		 	font-size: 1.5em;
			padding-bottom:20px;
		}
	}
	p {
		font-size: 1.5em;
		margin-top: 25px;
	}

}
